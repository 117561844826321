(function($) {
"use strict";

var App = {

	el : {
		body : $('body'),
		header : $('#page-header'),
		navDropdown : $('#mobile-nav .dropdown-toggle'),
		navMenu : $('.nav-button'),
		banner : $('#banner'),
		eventBox : $('.sidebar .event-box a'),
		mobileDropdown : $('#mobile-nav .dropdown-toggle'),
		mobileListNav : $('#main-nav select, #banner-nav select'),
		window : $(window),
		document : $(document)
	},

	init : function() {
		$('html').addClass('js');

		this.deviceWidth = screen.width;
		this.widthTablet = 770;
		this.widthMobile = 570;

		this.events();
		this.locationTabs.init();
		this.bannerOverlay();

		this.initBanner();
		this.initResponsive();
		this.initModal();
		this.initChosen();

		if( this.deviceWidth >= this.widthTablet ) {
			this.initDropdowns();
		}
	},

	events : function() {
		this.el.navMenu.off('click.odc').on('click.odc', $.proxy(this.onNavToggle, this));

		this.el.eventBox.on('click', $.proxy(this.showEventDetails, this));

		this.el.mobileDropdown.off('click').on('click', $.proxy(this.toggleNavDropdown, this));

		this.el.navDropdown.off('click').on('click', $.proxy(this.onMobileDropdownToggle, this));

		this.el.mobileListNav.off('change').on('change', $.proxy(this.onListNavChange, this));

		this.el.window.off('resize.overlay').on('resize.overlay', $.proxy(this.bannerOverlay, this));

		this.el.document.off('gform_post_render').on('gform_post_render', $.proxy(this.pluginsInit, this));
	},

	/**
	 *	Toggle the mobile nav menu
	 *
	 * 	@event App#onNavToggle:click
	 *	@param {object} e - event object
	 */
	onNavToggle : function(e) {
		var $this = $(e.target),
			$el = $('#page-header').add($('#mobile-nav')),
			$page = this.el.body;

		e.preventDefault();

		$page.removeClass('drawer-open');

		if(! $page.hasClass('nav-open')) {
			$page.addClass('nav-open');

			$(document).on('page:before-change keydown.navdrop.odc click.navdrop.odc', function(e) {
				if(typeof e.keyCode !== 'undefined') {
					if(e.keyCode === 27) {
						$page.removeClass('nav-open');
						$(document).off('.navdrop');
					}
				} else if(! $(e.target).parents().is($el) && ! $(e.target).is($el)) {
					$page.removeClass('nav-open');
					$(document).off('.navdrop.odc');
				}
			});
		} else {
			$page.removeClass('nav-open');
			$(document).off('.navdrop.odc');
		}
	},

	/**
	 *	Toggle the mobile nav dropdown
	 *
	 * 	@event App#onMobileDropdownToggle:click
	 *	@param {object} e - event object
	 */
	onMobileDropdownToggle : function(e) {
		var $this = $(e.target),
			$parent = $this.closest('.dropdown');

		e.preventDefault();

		if($parent.hasClass('open')) {
			$parent.removeClass('open');
		} else {
			$parent.addClass('open');
		}
	},

	/**
	 *	Event - Select list navigation
	 */
	onListNavChange : function(e) {

		// Quit if wrong element
		if( typeof e !== 'object' || typeof e.target !== 'object' ) return;
		if( e.target.nodeName !== 'SELECT' ) return;

		// Define vars
		var $this = e.currentTarget;
		var url = $this.value;

		// Change the page
		if( url ) document.location.href = url;

	},

	/*
	 * Header Location Tabs
	 */
	locationTabs :  {

		tabContainer : $("#header-sidebar .container-inside"),

		init:function() {

			var self = this;

			// Dewfine vars
			var $tabContainer = self.tabContainer,
				$tabCount = 0,
				$positionCount = 0,
				$tabs = $tabContainer.find('.tab'),
				$tabWidth = $tabContainer.children('.tab').length * $tabs.width(),
				$tabHeight = $tabContainer.children('.tab').outerHeight(),
				$newHeight,
				curTab = ($.cookie) ? $.cookie('dkd_current_tab') : 'louisville',
				url = window.location.pathname;

			// Build
			$tabContainer.wrapInner('<div class="tabwrap" />');
			$(".tabwrap").width( $tabWidth );
			$newHeight = $tabHeight;

			$tabContainer.find(".tab").each(function(){
				$tabCount++;
				$(this).addClass('tab' + $tabCount);

				$(this).css({
					'position' : 'absolute',
					'top' : '0',
					'left' : '0'
				});

				$('.tab'+$tabCount).css({
					'left' : '200' * $positionCount
				});

				$positionCount++;
			});

			$("#location-tabs > p").removeClass('active');
			$tabContainer.find(".tab").removeClass('activeTab');
			$("#location-tabs .tab-lou").addClass('active');
			$tabContainer.find(".tab1").addClass('activeTab');

			// Set new albany to active
			if(typeof url !== 'undefined') {
				url = url.split('/');

				if(url.length >= 3){
					url = url[2];
				}
			}

			if( curTab === 'new-albany' || url === 'new-albany' ) {
				$('> p','#location-tabs').removeClass('active');
				$('> p.tab-na','#location-tabs').addClass('active');

				$tabs.removeClass('activeTab');
				$('.tab2', $tabContainer).addClass('activeTab');

				$('.tabwrap', $tabContainer).css({'marginLeft' : '-200px'});

				if( $tabContainer.find(".activeTab") ) {
					$newHeight = $tabContainer.find('.activeTab').outerHeight();
				}

				$tabContainer.css({
					'height' : $newHeight
				});
			}

			// Events
			$("#location-tabs .tab-lou").bind('click',function(){
				self.showSlide1();
				return false;
			});

			$("#location-tabs .tab-na").bind('click',function(){
				self.showSlide2();
				return false;
			});

			self.buildStyles();

		},

		buildStyles : function() {

			var self = this;
			var $tabContainer = self.tabContainer;
			var $newHeight;

			if( $tabContainer.find(".activeTab") ) {
				if($('.sidebar').is(':hidden')) {
					$('.sidebar').show();
					$newHeight = $tabContainer.find('.activeTab').outerHeight();
					$('.sidebar').removeAttr('style');
				} else {
					$newHeight = $tabContainer.find('.activeTab').outerHeight();
				}
			}

			$tabContainer.css({
				'height' : $newHeight
			});

			$tabContainer.find('.tabwrap').css({
				'position' : 'absolute',
				'top' : '0',
				'left' : '0',
				'height' : $newHeight,
				'overflow' : 'hidden'
			});

		},

		showSlide1 : function() {

			var self = this;
			var $tabContainer = self.tabContainer;
			var	$animSpeed = 300;

			if( ! $(this).hasClass('active') ) {
				$tabContainer.find('.tabwrap').animate({
					'marginLeft' : '0'
				},$animSpeed);

				$tabContainer.find('.tab').removeClass('activeTab');
				$tabContainer.find('.tab1').addClass('activeTab');

				$("#location-tabs").children().removeClass('active');
				$("#location-tabs .tab-lou").addClass('active');

				$.cookie('dkd_current_tab', 'louisville', { path: '/' });
				self.buildStyles();
			}

		},

		showSlide2 : function() {

			var self = this;
			var $tabContainer = self.tabContainer;
			var	$animSpeed = 300;

			if( ! $(this).hasClass('active') ) {
				$tabContainer.find('.tabwrap').animate({
					'marginLeft' : '-200'
				},$animSpeed);

				$tabContainer.find('.tab').removeClass('activeTab');
				$tabContainer.find('.tab2').addClass('activeTab');

				$("#location-tabs").children().removeClass('active');
				$("#location-tabs .tab-na").addClass('active');

				$.cookie('dkd_current_tab', 'new-albany', { path: '/' });
				self.buildStyles();
			}

		}

	},

	/*
	 * Resize Banner Overlay
	 */
	bannerOverlay :  function() {
		// Define vars
		var	$overlay = $("#banner-bg"),
			$element = $("#header-bar .inside"),
			$offset = $element.offset().left;

		// Build
		if( $offset && $overlay ) {
			$overlay.css({
				'width' : $offset
			});
		}
	},

	/**
	 *	Initialize Dropdown menus
	 *
	 *	@requires $.fn.hoverIntent
	 */
	initDropdowns : function() {
		var self = this,
			$nav = $("#main-nav ul li", this.el.header);

		if(!$nav.length) return;

		if ( $.fn.hoverIntent) {

			$nav.hoverIntent({
				sensitivity: 1,
				interval: 150,
				timeout: 280,

				over: function() {
					var $dropdown = $("> ul, > .dropdown", $(this));
					if($dropdown.length) {
						$(this).addClass('active');
					}
				},
				out: function() {
					var $dropdown = $("> ul, > .dropdown", $(this));
					if($dropdown.length) {
						$(this).removeClass('active');
						setTimeout(function() {$dropdown.removeClass('edge');}, 200);
					}
				}
			});

		} else {

			console.warn('Missing hoverIntent plugin');

			$nav.hover(
			     function() {
					var $dropdown = $("> ul, > .dropdown", $(this));
					if($dropdown.length) {
						$(this).addClass('active');
					}
				},
			     function() {
					var $dropdown = $("> ul, > .dropdown", $(this));
					if($dropdown.length) {
						$(this).removeClass('active');
						setTimeout(function() {$dropdown.removeClass('edge');}, 200);
					}
				}
			);

		}
	},

	/*
	 * Banner Cycle
	 */
	initBanner :  function() {
		// Define vars
		var $banner = this.el.banner;

		$(window).on('load', function() {
			$(".container", $banner).not(':first').hide();
			$("#banner-pager").addClass("slides" + $('.container', $banner).length);

			if($.fn.cycle && $('.container', $banner).length > 2) {
				$banner.cycle({
					fx: 'fade',
					speed: 600,
					log: false,
					slides: '> .container',
					swipe: true,
					slideResize: 0,
					pager: '#banner-pager'
				});
			}

		});
	},

	/*
	 * ResponseJS
	 */
	initResponsive :  function() {

		// Response
		if(typeof Response === 'object') {
			Response.create({
				prop: "width",
				breakpoints: [900,600]
			});
		}
	},

	/*
	 * Modal
	 */
	initModal :  function() {
		// swipebox
		if( $.fn.swipebox ) {
			$("#content a[href$='jpg'],#content a[href$='png'],#content a[href$='gif']").each(function() {
				if(! $(this).attr("data-modal") ) {
					$(this).attr("data-modal","true");
				}
			});

			$("a[data-modal^='true']").swipebox();
		}
	},

	/*
	 * Chosen
	 */
	initChosen :  function() {
		// Chosen
		if( $.fn.select2 ) {
			$("#content select[multiple]").select2({
				minimumResultsForSearch: 10,
				allowClear: true
			});
		}

	}

};

return App.init();
}).call(this, jQuery);